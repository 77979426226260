import '@reactour/popover/dist/index.css';
import { TourProvider } from '@reactour/tour';
import * as Sentry from '@sentry/browser';
import { App as AntdAppWrapper, ConfigProvider } from 'antd';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import SocketIoWrapper from './SocketIoWrapper';
import OnboardingContentComponent from './components-new/base/onboarding/OnboardingContentComponent';
import GoogleAnalysisTracker from './components-new/googleAnalysis/GoogleAnalysisTracker';
import useInternetStatus from './components/hooks/useInternetStatus';
import { getAntDesignLocaleFromLanguageCode } from './i18n';

ReactGA.initialize(process.env.REACT_APP_GA);

export const CHAOS_THEME = {
  components: {
    Segmented: {
      colorBgElevated: '#face67',
    },
    Tree: {
      controlItemBgHover: 'transparent',
    },
    Slider: {
      dotBorderColor: '#ffe98f',
      handleColor: '#ffe98f',
      trackBg: '#ffe98f',
      dotActiveBorderColor: '#ffdb66',
      handleActiveColor: '#ffdb66',
      trackHoverBg: '#ffdb66',
    },
    Form: {
      itemMarginBottom: 0,
    },
    Reporting: {
      iconSize: 16,
    },
  },
  token: {
    colorPrimary: '#fab514',
    colorInfo: '#fab514',
    colorPrimaryText: '#fab514',
    fontFamily: '"Roboto", Helvetica, sans-serif',
    borderRadius: 2,
    fontSize: 12,
    colorPrimaryHover: '#face67',
  },
};

const POPOVER_OPPOSITE_SIDE = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

const generateArrowAndPosition = (popoverState) => {
  const { position, verticalAlign, horizontalAlign, helperRect, targetRect } =
    popoverState;
  if (!position || position === 'custom' || position === 'center') {
    return {};
  }

  const arrowWidth = 16;
  const arrowHeight = 12;
  const color = '#323232';
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = isVertical
    ? helperRect.width / 2
    : helperRect.height / 2;

  const horizontalCompensation =
    helperRect.width > targetRect.width
      ? (helperRect.width - targetRect.width) / -2
      : targetRect.width / 2 - helperRect.width / 2;

  const verticalCompensation =
    helperRect.height > targetRect.height
      ? (helperRect.height - targetRect.height) / -2
      : targetRect.height / 2 - helperRect.height / 2;

  const coords = {
    top: [
      targetRect.x + horizontalCompensation,
      targetRect.top - helperRect.height - arrowHeight * 1.5,
    ],
    right: [targetRect.right, targetRect.y + verticalCompensation],
    bottom: [
      targetRect.x + horizontalCompensation,
      targetRect.bottom + arrowHeight * 1.5,
    ],
    left: [
      targetRect.left - arrowWidth * 1.5 - helperRect.width,
      targetRect.y + verticalCompensation,
    ],
  };

  return {
    [`--rtp-arrow-${
      isVertical ? POPOVER_OPPOSITE_SIDE[horizontalAlign] : verticalAlign
    }`]: `${spaceFromSide - arrowHeight / 2}px`,
    [`--rtp-arrow-${POPOVER_OPPOSITE_SIDE[position]}`]: `${-arrowHeight + 2}px`,
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${
      arrowWidth / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${
      arrowWidth / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${arrowHeight}px solid ${color}`,
    transform: `translate(${Math.round(coords[position][0])}px, ${Math.round(
      coords[position][1],
    )}px)`,
  };
};

export const BASE_HIGHLIGHTED_AREA_STYLE = {
  stroke: '#fab514',
  strokeWidth: '2px',
  display: 'block',
  pointerEvents: 'none',
  rx: 4,
};

export const BASE_POPOVER_STYLE = {
  backgroundColor: '#323232',
  borderRadius: 4,
  minWidth: 180,
  maxWidth: 220,
  padding: 16,
};

const TOUR_STYLES = {
  popover: (base, state) => ({
    ...base,
    ...BASE_POPOVER_STYLE,
    ...generateArrowAndPosition(state),
  }),
  maskWrapper: (base) => ({
    ...base,
    color: 'transparent',
  }),
  highlightedArea: (base) => ({
    ...base,
    ...BASE_HIGHLIGHTED_AREA_STYLE,
  }),
};

const App = () => {
  const [locale, setLocale] = useState(getAntDesignLocaleFromLanguageCode());

  const internetStatus = useInternetStatus();

  useEffect(() => {
    Sentry.setContext('internet status', { internetStatus });
  }, [internetStatus]);

  useEffect(() => {
    i18n.on('languageChanged', (language) => {
      setLocale(getAntDesignLocaleFromLanguageCode(language));
    });
  }, []);

  return (
    <ConfigProvider theme={CHAOS_THEME} locale={locale}>
      <AntdAppWrapper>
        <BrowserRouter>
          <GoogleAnalysisTracker>
            <TourProvider
              steps={[]}
              styles={TOUR_STYLES}
              showPrevNextButtons={false}
              showBadge={false}
              showNavigation={false}
              showDots={false}
              showCloseButton
              scrollSmooth
              onClickMask={() => null}
              maskPadding={[20]}
              ContentComponent={OnboardingContentComponent}
            >
              <SocketIoWrapper>
                <AppRoutes />
              </SocketIoWrapper>
            </TourProvider>
          </GoogleAnalysisTracker>
        </BrowserRouter>
      </AntdAppWrapper>
    </ConfigProvider>
  );
};

export default App;
