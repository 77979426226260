import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { features } from '../../pages/forecast/helpers/features';
import {
  firebaseDeleteApi,
  firebaseGetApi,
  firebasePatchApi,
  firebasePostApi,
} from '../../services/firebaseApi';
import { DATASET_TYPE_KEY } from '../helper';

const BASE_URL = '/real-estate';

const realEstateEndPoints = {
  location: `${BASE_URL}/location`,
  rents: `${BASE_URL}/rents`,
  prediction: `${BASE_URL}/prediction`,
  locations: `${BASE_URL}/locations`,
  sharepointFile: `${BASE_URL}/sharepoint-file`,
  sharepointLocations: `${BASE_URL}/sharepoint-locations`,
  municipalitySummary: `${BASE_URL}/municipality-summary`,
  services: `${BASE_URL}/services`,
  demographics: `${BASE_URL}/demographics`,
  dataSources: `${BASE_URL}/data-sources`,
};

export const locationsQueryKey = 'real-estate-locations';
const locationsMutateKey = 'mutate-real-estate-locations';
const rentPredictionQueryKey = 'real-estate-rent-prediction';
const modelLineQueryKey = 'real-estate-model-line';
const municipalitySummary = 'real-estate-municipality-summary';
const allLocationsQueryKey = 'real-estate-delete-all-places';
const servicesQueryKey = 'real-estate-services';
const demographicsQueryKey = 'real-estate-demographics';
const dataSourcesQueryKey = 'real-estate-data-sources';
const sharepointFileQueryKey = 'real-estate-sharepoint-dataset';

const transformRoomFilters = (fetchedResult) => {
  const intRooms = fetchedResult?.filters?.numberOfRooms || [];
  const strRooms = intRooms.map((room) => `${room}`);
  const numberOfRooms = strRooms.length < 5 ? strRooms : [];

  return {
    ...fetchedResult,
    filters: {
      ...fetchedResult?.filters,
      numberOfRooms,
    },
  };
};

export const useRealEstateAddLocation = () => {
  const queryClient = useQueryClient();

  const addLocationApiCall = (payload) =>
    firebasePostApi(realEstateEndPoints.location, payload);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [locationsMutateKey],
    mutationFn: addLocationApiCall,
    onSuccess: async (newLocation) => {
      const prevLocations = queryClient.getQueryData([locationsQueryKey]);

      const updatedData = {
        ...prevLocations,
        locations: [...prevLocations.locations, newLocation],
      };

      queryClient.setQueriesData(
        { queryKey: [locationsQueryKey] },
        updatedData,
      );
    },
    onError: () =>
      queryClient.invalidateQueries({
        queryKey: [locationsQueryKey],
        exact: false,
      }),
  });

  return { addLocation: mutateAsync, isPending };
};

export const useRealEstateLocations = () => {
  const fetchLocations = () => firebaseGetApi(realEstateEndPoints.locations);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [locationsQueryKey],
    queryFn: fetchLocations,
  });

  return {
    locations: data?.locations || [],
    isLoading: isLoading || isFetching,
  };
};

export const useRealEstateDeleteLocation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteLocation, isPending } = useMutation({
    mutationKey: [locationsMutateKey],
    mutationFn: ({ locationId, dataset }) =>
      firebaseDeleteApi(realEstateEndPoints.location, {
        locationId,
        dataset,
      }),
    onMutate: async ({ locationId }) => {
      await queryClient.cancelQueries({
        queryKey: [locationsQueryKey],
      });

      const prevLocations = queryClient.getQueryData([locationsQueryKey]);

      const newLocations = prevLocations?.locations?.filter(
        (item) => item.id !== locationId,
      );

      const updatedData = {
        ...prevLocations,
        locations: newLocations,
      };

      queryClient.setQueriesData(
        { queryKey: [locationsQueryKey] },
        updatedData,
      );
    },
    onError: () =>
      queryClient.invalidateQueries({
        queryKey: [locationsQueryKey],
        exact: false,
      }),
  });

  return { deleteLocation, isLoading: isPending };
};

export const useRealEstateUpdateLocation = () => {
  const queryClient = useQueryClient();

  const updateLocationApiCall = (placeData) =>
    firebasePatchApi(realEstateEndPoints.location, placeData);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [locationsMutateKey],
    mutationFn: updateLocationApiCall,
    onSuccess: async (updatedLocation) => {
      const prevLocations = queryClient.getQueryData([locationsQueryKey]);

      const updatedLocations = prevLocations?.locations?.map((item) =>
        item.id === updatedLocation.id ? updatedLocation : item,
      );

      const updatedData = {
        ...prevLocations,
        locations: updatedLocations,
      };

      queryClient.setQueriesData(
        { queryKey: [locationsQueryKey] },
        updatedData,
      );

      if (updatedLocation?.id) {
        await queryClient.resetQueries({
          queryKey: [rentPredictionQueryKey, parseInt(updatedLocation?.id, 10)],
          exact: false,
        });
        await queryClient.resetQueries({
          queryKey: [modelLineQueryKey, parseInt(updatedLocation?.id, 10)],
          exact: true,
        });
        await queryClient.invalidateQueries({
          queryKey: [servicesQueryKey, updatedLocation?.id],
          exact: true,
        });
        await queryClient.invalidateQueries({
          queryKey: [demographicsQueryKey, updatedLocation?.id],
          exact: true,
        });
      }
    },
  });

  return { updateLocation: mutateAsync, isLoading: isPending };
};

export const useRealEstateRents = ({
  locationId,
  filters,
  isEnabled,
  dataset,
}) => {
  const fetchFilteredRents = ({ signal }) => {
    const params = {
      LocationId: locationId,
      Filters: filters || {},
      Dataset: dataset,
    };

    return firebasePostApi(realEstateEndPoints.rents, params, {
      signal,
    });
  };

  const isFetchEnabled = Boolean(locationId) && isEnabled;

  const { data, isLoading } = useQuery({
    queryKey: [
      rentPredictionQueryKey,
      parseInt(locationId, 10),
      { ...filters },
    ],
    queryFn: fetchFilteredRents,
    enabled: isFetchEnabled,
    // keep the cache for 10 minutes
    gcTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading: isLoading && isEnabled };
};

export const useRealEstatePredictionModel = ({ locationId, dataset }) => {
  const fetchModelLine = () => {
    const params = { locationId, dataset };

    return firebaseGetApi(realEstateEndPoints.prediction, params);
  };

  const isEnabled = Boolean(locationId);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [modelLineQueryKey, parseInt(locationId, 10)],
    queryFn: fetchModelLine,
    enabled: isEnabled,
    select: (prediction) => transformRoomFilters(prediction),
  });

  return { data, isLoading: isLoading && isEnabled, error, isError };
};

export const useRealEstateMunicipalitySummary = ({ municipality }) => {
  const params = { Municipality: municipality };

  const fetchSummary = () =>
    firebaseGetApi(realEstateEndPoints.municipalitySummary, params);

  const enabled = Boolean(municipality);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [municipalitySummary, municipality],
    queryFn: fetchSummary,
    enabled,
  });

  return {
    data,
    isLoading: isLoading || isFetching,
  };
};

export const useRealEstateDeleteAllLocations = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteAllLocations } = useMutation({
    mutationKey: [allLocationsQueryKey],
    mutationFn: () => firebaseDeleteApi(realEstateEndPoints.locations),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [locationsQueryKey],
      });

      const prevLocations = queryClient.getQueryData([locationsQueryKey]);

      const newLocations = prevLocations?.locations?.filter(
        (item) => item.dataset !== DATASET_TYPE_KEY.ownLocations,
      );

      const updatedData = {
        ...prevLocations,
        locations: newLocations,
      };

      queryClient.setQueriesData(
        { queryKey: [locationsQueryKey] },
        updatedData,
      );
    },
    onError: () =>
      queryClient.invalidateQueries({
        queryKey: [locationsQueryKey],
        exact: false,
      }),
  });
  return { deleteAllLocations };
};

export const useRealEstateServicePoints = ({ locationId, dataset }) => {
  const { globalFeatures } = useSelector((state) => ({
    globalFeatures: state.user.globalFeatures,
  }));

  const params = { locationId, dataset };
  const fetchServices = () =>
    firebaseGetApi(realEstateEndPoints.services, params);

  const enabled =
    Boolean(locationId) && globalFeatures.includes(features.SERVICES);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [servicesQueryKey, locationId],
    queryFn: fetchServices,
    enabled,
  });

  return {
    data,
    isLoading: isLoading || isFetching,
  };
};

export const useRealEstateDemographics = ({ locationId, dataset }) => {
  const { globalFeatures } = useSelector((state) => ({
    globalFeatures: state.user.globalFeatures,
  }));

  const params = { locationId, dataset };
  const fetchDemographics = () =>
    firebaseGetApi(realEstateEndPoints.demographics, params);

  const enabled =
    Boolean(locationId) &&
    globalFeatures.includes(features.ADVANCED_DEMOGRAPHICS);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [demographicsQueryKey, locationId],
    queryFn: fetchDemographics,
    enabled,
  });

  return {
    data,
    isLoading: isLoading || isFetching,
  };
};

export const useRealEstateDataSources = ({ isEnabled }) => {
  const fetchDataSources = () =>
    firebaseGetApi(realEstateEndPoints.dataSources);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [dataSourcesQueryKey],
    queryFn: fetchDataSources,
    enabled: isEnabled,
  });

  return {
    data: data?.data || [],
    isLoading: isLoading || isFetching,
  };
};

export const useRealEstateSharepointFileDataset = () => {
  const fetchSharepointDataset = () =>
    firebaseGetApi(realEstateEndPoints.sharepointFile);

  const { data } = useQuery({
    queryKey: [sharepointFileQueryKey],
    queryFn: fetchSharepointDataset,
  });

  return {
    data: data || [],
  };
};
