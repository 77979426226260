import dayjs from 'dayjs';
import Papa from 'papaparse';
import { is, isNil, keys } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { countries } from '../helpers/country';
import { isNilOrPlaceholder } from '../helpers/extra';
import HomePinIcon from './images/home_pin_filled.svg';

const isNumber = is(Number);

export const NOT_AVAILABLE_VALUE = '–';

export const DWELLING_TYPES_DATASET = {
  rowHouse: 'rowHouse',
  semiDetachedHouse: 'semiDetachedHouse',
  detachedHouse: 'detachedHouse',
  apartment: 'apartment',
};

export const valueOrNotAvailable = (value) =>
  isNil(value) || value === '' ? NOT_AVAILABLE_VALUE : value;

const getCountryCurrency = (country) => {
  switch (country) {
    case countries.FINLAND:
      return 'EUR';
    case countries.DENMARK:
      return 'DKK';
    default:
      return null;
  }
};

export const getDwellingTypes = (country) => {
  if (country === countries.FINLAND) {
    return DWELLING_TYPES_DATASET;
  }
  return {};
};

export const formatCurrency = (
  value,
  country = null,
  maximumFractionDigits = 1,
) => {
  const currencyCode = getCountryCurrency(country);
  if (isNilOrPlaceholder(value) || !isNumber(value)) {
    return NOT_AVAILABLE_VALUE;
  }

  const currencyStyle = currencyCode
    ? {
        style: 'currency',
        currency: currencyCode,
      }
    : {};

  return Intl.NumberFormat('fi-FI', {
    ...currencyStyle,
    minimumFractionDigits: 0,
    maximumFractionDigits,
  })
    .format(value)
    .replace(',', '.');
};

export const getRentSqmRange = (
  minRentPerSquareMeter,
  maxRentPerSquareMeter,
  countryCode,
) =>
  minRentPerSquareMeter && maxRentPerSquareMeter
    ? `${formatCurrency(minRentPerSquareMeter)} - ${formatCurrency(maxRentPerSquareMeter, countryCode)}`
    : NOT_AVAILABLE_VALUE;

export const DEFAULT_BUILDING_YEAR_RANGE = [1900, 1940, 1980, dayjs().year()];

export const REAL_ESTATE_FEATURE = {
  realEstateAnalysis: 'real-estate-analysis',
};

export const NUMBER_OF_ROOMS_DATASET = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5+',
};

export const AMENITY_TYPES_DATASET = {
  balcony: 'balcony',
  ownSauna: 'ownSauna',
  elevator: 'elevator',
};

export const mapToMultiSelectOptionsDataset = (optionsDataset, t) =>
  optionsDataset.sort().map((option) => ({
    label: t(option, option),
    value: option,
  }));

export const useRentRegressionChartCsvDownload = (regressionData, fileName) => {
  const { t } = useTranslation('', {
    keyPrefix: 'realEstate.csvDownload',
  });

  const addAmenitiesKeyValue = useCallback(
    (rent) =>
      keys(AMENITY_TYPES_DATASET).reduce(
        (amenities, amenity) => ({
          ...amenities,
          [amenity]: rent?.buildingAmenities?.includes(amenity)
            ? t('yes')
            : t('no'),
        }),
        {},
      ),
    [t],
  );

  const downloadData = useMemo(
    () =>
      regressionData.map((rent) => ({
        ...rent,
        ...addAmenitiesKeyValue(rent),
      })),
    [addAmenitiesKeyValue, regressionData],
  );

  const amenitiesColumns = useMemo(
    () =>
      keys(AMENITY_TYPES_DATASET).map((amenity) => ({
        label: t(`buildingAmenities.${amenity}`),
        key: amenity,
      })),
    [t],
  );

  const dataColumns = useMemo(
    () => [
      { key: 'area', label: t('area') },
      { key: 'rentSqm', label: t('rentSqm') },
      { key: 'floor', label: t('floor') },
      { key: 'numberOfRooms', label: t('numberOfRooms') },
      ...amenitiesColumns,
    ],
    [t, amenitiesColumns],
  );

  const downloadRentRegressionDataCsv = useCallback(() => {
    if (!regressionData?.length) {
      return;
    }

    const columns = dataColumns.map(({ key }) => key);
    const headers = dataColumns.map(({ label }) => label);

    const headerCsvRow = Papa.unparse({
      fields: headers,
      data: [],
    });

    const bodyCsvRows = Papa.unparse(downloadData, {
      columns,
      header: false,
    });

    const blob = new Blob([headerCsvRow + bodyCsvRows], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  }, [dataColumns, downloadData, fileName, regressionData?.length]);

  return { downloadRentRegressionDataCsv };
};

export const RENT_COMPARISON_LOCATION_COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
];

export const REAL_ESTATE_LOCATIONS_CUSTOM_ICON =
  'REAL_ESTATE_LOCATIONS_CUSTOM_ICON';

export const addRealEstateCustomIconsToMap = async (map) =>
  new Promise((resolve) => {
    const symbol = new Image(20, 20);
    symbol.onload = async () => {
      map.addImage(REAL_ESTATE_LOCATIONS_CUSTOM_ICON, symbol, {
        sdf: true,
      });
      resolve();
    };
    symbol.src = HomePinIcon;
  });

export const SHAREPOINT_LOCATION_COLOR = '#08979c';

export const DATASET_TYPE_KEY = {
  ownLocations: 'own-locations',
  sharepoint: 'sharepoint',
};
