import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { logoutUser, updateUserConfig } from './actions';
import LoadingIndicator from './components-new/base/loadingIndicator/LoadingIndicator';
import Sidebar from './components/Sidebar';
import { lazyWithRetry } from './helpers/lazyLoading';
import ForecastUpgradeModal from './pages/forecast/forms/ForecastUpgradeModal';
import { features } from './pages/forecast/helpers/features';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Forecast = lazyWithRetry(() => import('./dashboard/forecast/Forecast'));
const Retail = lazyWithRetry(() => import('./retail/Retail'));
const RealEstate = lazyWithRetry(() => import('./realEstate/RealEstate'));
const RealEstateLocation = lazyWithRetry(
  () => import('./realEstate/components/RealEstateLocation'),
);
const RealEstateRentComparison = lazyWithRetry(
  () => import('./realEstate/rentComparison/RentComparison'),
);
const MarketAnalysisPlaceComparison = lazyWithRetry(
  () =>
    import('./retail/marketAnalysis/placeComparison/MarketAnalysisComparison'),
);
const Reports = lazyWithRetry(() => import('./reports-v2/ReportList'));
const ReportBuilder = lazyWithRetry(() => import('./reports-v2/ReportBuilder'));
const Settings = lazyWithRetry(() => import('./pages/Settings'));

const Root = ({ user, logoutUser: logout, updateUser }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const handleIsSidebarOpen = React.useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  const forecastRef = React.createRef();
  const handleBackToForecast = () => {
    if (forecastRef?.current) {
      forecastRef?.current?.wrappedInstance?.backToHome();
    }
  };

  const renderReportRouteItems = () => [
    [
      <Route path="reports" key="reports" Component={Reports} />,
      <Route path="reports/new" key="reports/new" Component={ReportBuilder} />,
      <Route
        path="reports/edit/:reportId"
        key="reports/edit/:reportId"
        Component={ReportBuilder}
      />,
    ],
  ];

  const renderRealEstateRouteItems = () => [
    <Route path="real-estate" key="real-estate" Component={RealEstate} />,
    <Route
      path="real-estate/:dataset/:realEstateId/*"
      key="real-estate/:dataset/:realEstateId/*"
      Component={RealEstateLocation}
    />,
    <Route
      path="real-estate/rents-comparison"
      key="real-estate/rents-comparison"
      Component={RealEstateRentComparison}
    />,
  ];

  const includesRetailAnalysis =
    user?.globalFeatures?.includes(features.RETAIL_MARKET_ANALYSIS) ||
    user?.globalFeatures?.includes(features.RETAIL_CATCHMENT_ANALYSIS);

  const includesRealEstateAnalysis = user?.globalFeatures?.includes(
    features.REAL_ESTATE_ANALYSIS,
  );

  const renderRetailRouts = () => [
    <Route path="retail/*" Component={Retail} key="retail" />,
    <Route
      path="retail/market-analysis/comparison"
      Component={MarketAnalysisPlaceComparison}
      key="retail-market-analysis-comparison"
    />,
  ];

  return (
    <div className="dashboard__wrapper">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        user={user.info}
        logout={logout}
        handleClickOutside={() => {
          if (isSidebarOpen) {
            handleIsSidebarOpen();
          }
        }}
        handleBackToForecast={handleBackToForecast}
        availableGlobalFeatures={user.globalFeatures}
        updateUserInfo={updateUser}
      />
      <div
        className="dashboard__content"
        style={{
          marginLeft: 50,
          transition: 'margin-left 0.1s ease',
        }}
      >
        <Suspense
          fallback={<LoadingIndicator positionAbsoluteCenter size={42} />}
        >
          <Routes>
            <Route index element={<Navigate to="/insights" />} />
            <Route
              path="insights"
              element={
                user?.globalFeatures?.includes(features.INSIGHTS) ? (
                  <Forecast />
                ) : (
                  <ForecastUpgradeModal />
                )
              }
            />

            {includesRetailAnalysis && renderRetailRouts()}

            {includesRealEstateAnalysis && renderRealEstateRouteItems()}

            <Route
              path="settings"
              element={<Settings isSidebarOpen={isSidebarOpen} />}
            />
            {user?.globalFeatures?.includes(features.REPORTING) &&
              renderReportRouteItems()}

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

Root.defaultProps = {
  user: undefined,
};

Root.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  logoutUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  logoutUser,
  updateUser: updateUserConfig,
})(Root);
