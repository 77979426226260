import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import { debounce } from 'throttle-debounce';

import { features } from './pages/forecast/helpers/features';
import { locationsQueryKey } from './realEstate/data/realEstateDataProvider';

const SOCKETIO_SERVER_URL = process.env.REACT_APP_SOCKETIO_SERVER_URL;

const REAMS_SHAREPOINT_LOCATIONS_RELOAD_EVENT =
  'reamsSharepointLocationsReloadEvent';

// 10 minutes until the socket disconnects if the tab is not in focus
const DISCONNECT_TIMEOUT_IN_MS = 10 * 60 * 1000;

const SocketIoWrapper = ({ children, organizationId, globalFeatures }) => {
  const queryClient = useQueryClient();

  const socket = useRef();
  const closeConnectionTimeoutIdRef = useRef();
  const isTabVisible = useRef(document.hasFocus());

  const closeSocket = useCallback(() => {
    socket.current?.disconnect();
    socket.current = null;
  }, []);

  const logSentryError = useMemo(
    () =>
      debounce(
        60 * 1000,
        () => {
          Sentry.withScope((scope) => {
            scope.setTag('component', 'SocketIoWrapper');
            scope.setLevel('error');
            Sentry.captureMessage(
              'Error connecting to socket io server: socket-initializatio',
            );
          });
        },
        { atBegin: true },
      ),
    [],
  );

  const initializeSocketConnection = useCallback(() => {
    if (
      !organizationId ||
      !globalFeatures.includes(features.REAL_ESTATE_ANALYSIS) ||
      socket.current
    ) {
      return undefined;
    }

    socket.current = io(SOCKETIO_SERVER_URL, {
      auth: { token: localStorage.getItem('token') },
    });

    socket.current.on(REAMS_SHAREPOINT_LOCATIONS_RELOAD_EVENT, () => {
      queryClient.invalidateQueries({
        queryKey: [locationsQueryKey],
        exact: false,
      });
    });

    socket.current.on('error', (e) => {
      if (e === 'socket-initialization') {
        logSentryError();
        closeSocket();

        setTimeout(() => {
          if (isTabVisible.current) {
            initializeSocketConnection();
          }
        }, 5000);
      }
    });

    return () => {
      closeSocket();
    };
  }, [
    closeSocket,
    globalFeatures,
    logSentryError,
    organizationId,
    queryClient,
  ]);

  useEffect(() => {
    if (isTabVisible.current) {
      initializeSocketConnection();
    }
  }, [initializeSocketConnection]);

  const onWindowFocus = useCallback(() => {
    isTabVisible.current = true;
    clearTimeout(closeConnectionTimeoutIdRef.current);
    initializeSocketConnection();
  }, [initializeSocketConnection]);

  const onWindowBlur = useCallback(() => {
    closeConnectionTimeoutIdRef.current = setTimeout(() => {
      closeSocket();
    }, DISCONNECT_TIMEOUT_IN_MS);
    isTabVisible.current = false;
  }, [closeSocket]);

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, [onWindowBlur, onWindowFocus]);

  return children;
};

SocketIoWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  organizationId: PropTypes.string,
};

SocketIoWrapper.defaultProps = {
  organizationId: null,
};

function mapStateToProps(state) {
  return {
    organizationId: state?.user?.info?.companyId,
    globalFeatures: state?.user?.globalFeatures || [],
  };
}

export default connect(mapStateToProps)(SocketIoWrapper);
